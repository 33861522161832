
import { defineComponent } from 'vue'
import Header from '@/components/organisms/Header.vue'
import Footer from '@/components/organisms/Footer.vue'
export default defineComponent({
  components: {
    Header,
    Footer
  }
  // setup() {}
})
