import { AxiosError, AxiosRequestConfig } from 'axios'
import { BaseApi } from './base'
import authentication from '../authentication'
class AuthApi extends BaseApi {
  public constructor() {
    super(process.env.VUE_APP_API_URL)
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
    this.instance.interceptors.request.use(this._handleRequest)
  }

  public getDossiers = (query: string) => {
    return this.instance.get(`dossier/all?${query}`)
  }

  protected _handleError = (error: AxiosError) => {
    if (error.response?.status === 401) {
      authentication.signIn()
    }
    Promise.reject(error)
  }

  protected _handleRequest = (config: AxiosRequestConfig) => {
    authentication.acquireToken().then((token: string) => {
      config.headers.Authorization = `Bearer ${token}`
    })
    return config
  }
}

const authApi: AuthApi = new AuthApi()

export default authApi
