import axios, { AxiosInstance, AxiosResponse } from 'axios'

// eslint-disable-next-line
declare module 'axios' { interface AxiosResponse<T = any> extends Promise<T> {}}

export abstract class BaseApi {
  protected readonly instance: AxiosInstance

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected _handleResponse = ({ data }: AxiosResponse) => data
}
