import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './index'
// eslint-disable-next-line prettier/prettier
import type {State as RootState } from '@/store'
import dossiersApi from '@/api/dossiersApi'

export enum ActionTypes {
  GET_DOSSIERS = 'GET_DOSSIERS'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ActionTypes.GET_DOSSIERS]({ commit }: AugmentedActionContext, query: any): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_DOSSIERS]({ commit }, query) {
    const res = await dossiersApi.getDossiers(query)
    commit(MutationTypes.SET_DOSSIERS, res.rows)
  }
}
