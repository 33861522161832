import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Wrapper from '@/components/templates/Wrapper.vue'
import authentication from '../authentication'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Wrapper,
    meta: {
      requiresAuthentication: true
    },
    redirect: {
      name: 'Main'
    },
    children: [
      {
        path: '',
        name: 'Main',
        meta: {
          requiresAuthentication: true
        },
        component: () => import('@/components/pages/Home.vue')
      },
      {
        path: 'search/:id?/:name?',
        name: 'MainSearch',
        meta: {
          requiresAuthentication: true
        },
        component: () => import('@/components/pages/MainSearch.vue')
      },
      {
        path: 'zoeken_zonder_dossier',
        name: 'SearchWithoutFile',
        meta: {
          requiresAuthentication: true
        },
        component: () => import('@/components/pages/SearchWithoutFile.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/components/pages/Home.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Global route guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuthentication)) {
    // this route requires auth, check if logged in
    if (authentication.isAuthenticated()) {
      // only proceed if authenticated.
      next()
    } else {
      authentication.signIn()
    }
  } else {
    next()
  }
})

export default router
