import { mutations, Mutations } from './mutations'
import { actions, Actions, ActionTypes } from './actions'
import { Store as VuexStore, Module, CommitOptions, DispatchOptions } from 'vuex'
// eslint-disable-next-line prettier/prettier
import type { State as RootState } from '@/store'
import type { dossiersType } from '@/types/dossiers'

interface State {
  dossiers: dossiersType[]
  perPageItems: number
}

const state: State = {
  dossiers: [],
  perPageItems: 10
}

const user: Module<State, RootState> = {
  state,
  mutations,
  actions
}

// eslint-disable-next-line no-use-before-define
export { State, ActionTypes, Store }
export default user

type Store<S = State> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
}
