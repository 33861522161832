<template>
  <div class="footer-wrapper">
    <div class="block blue"></div>
    <div class="block grey">
      <div class="center-wrapper">
        <p>&#169; 2020 Nederlands Taxatie & Adviesbureau | Privacyverklaring</p>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.footer-wrapper
  display: flex
  flex-direction: column
  margin-top: 5.5em
.block
  height: 57px
  width: 100%
  display: flex
  align-items: center
  &.blue
    background: $primaryBlue
    border: 1px solid $white
    border-width: 1px 0 1px 0
  &.grey
    background: $grey3
    p
      @include font-source('Roboto', 11px, 400, $white)
</style>
