
import authentication from '../../authentication'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()

    const signOut = () => {
      authentication.signOut()
    }

    const redirectToHome = () => {
      router.push({ name: 'Main' })
    }

    return { signOut, redirectToHome }
  }
})
