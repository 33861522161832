import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import dossiers, { State as dossierState, Store as DossierStore } from './dossiers'

export interface State {
  dossiers: dossierState
}

export type RootStore = DossierStore<Pick<State, 'dossiers'>> & DossierStore<Pick<State, 'dossiers'>>
// ClientStore<Pick<State, 'client'>> &
// StudioStore<Pick<State, 'studio'>> &
// ProjectStore<Pick<State, 'project'>> &
// MessageStore<Pick<State, 'messages'>>

export const key: InjectionKey<Store<State>> = Symbol('store')

export const store = createStore<State>({
  modules: {
    dossiers
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useStore() {
  return baseUseStore(key)
}
