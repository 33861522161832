/* eslint-disable prettier/prettier */
import { MutationTree } from 'vuex'
import { State } from './index'
import type { dossiersType } from '@/types/dossiers'

export enum MutationTypes {
  SET_DOSSIERS = 'SET_DOSSIERS',
  USER_CREATED = 'USER_CREATED',
  SET_PER_PAGE_ITEMS = 'SET_PER_PAGE_ITEMS'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_DOSSIERS](state: S, dossiers: dossiersType[]): void
  [MutationTypes.SET_PER_PAGE_ITEMS](state: S, value: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_DOSSIERS](state, dossiers) {
    state.dossiers = dossiers
  },
  [MutationTypes.SET_PER_PAGE_ITEMS](state, value) {
    state.perPageItems = value
  }
}
